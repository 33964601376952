/* Global Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #d9ebff;
  color: #333;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* App Wrapper */
.App {
  text-align: center;
  background-color: #d9ebff;
  color: #333;
}

/* Headers with Gradual Lightening */
h1, h2, h3 {
  padding: 20px;
  border-radius: 8px;
  margin: 0;
  margin-bottom: -1px;
}

h1 {
  background-color: #a3c8ff;
}

h2 {
  background-color: #b3d9ff;
}

h3 {
  background-color: #c3eaff;
}

/* Section Styles */
section {
  background-color: #eaf6ff;
  padding: 15px;
  border-radius: 0;
  margin: 0;
}

/* Navigation Bar */
nav {
  background-color: #b3d9ff;
  padding: 15px;
  border-radius: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

nav ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

nav ul li {
  margin-right: 20px;
}

nav ul li:last-child {
  margin-right: 0;
}

nav ul li a {
  display: inline-block;
  text-decoration: none;
  color: #333;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

nav ul li a:hover {
  background-color: #d9ebff;
  transform: translateY(-2px);
}

nav ul li a.active {
  background-color: #d9ebff;
  color: #333;
  border-radius: 5px;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  nav {
    padding: 10px;
  }

  nav ul {
    flex-direction: column;
    align-items: flex-start;
  }

  nav ul li {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }

  nav ul li a {
    width: 100%;
    padding: 10px;
    text-align: left;
    box-sizing: border-box;
  }
}

/* Button Styles */
button {
  background-color: #b3d9ff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #a3c8ff;
}

/* Input Field */
input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  width: calc(100% - 22px);
}

input[type="text"]:focus {
  outline: none;
  border-color: #85c1e9;
}

/* Footer */
footer {
  background-color: #282c34;
  color: white;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

/* Additional Styling for Special Sections */
.donation-section {
  text-align: center;
  padding: 1em;
  border-top: 1px solid #ddd;
  background-color: #eaf6ff;
}

.donation-buttons {
  display: flex;
  justify-content: center;
  gap: 1em;
  margin-top: 1em;
}

.donation-logo {
  width: 100px;
  height: auto;
  transition: transform 0.3s;
}

.donation-logo:hover {
  transform: scale(1.1);
}

.github-best-practices {
  padding: 1em;
  background-color: #eaf6ff;
}

.github-best-practices pre {
  background: #ecf0f1;
  padding: 1em;
  border-radius: 5px;
  overflow-x: auto;
}

mark {
  background-color: yellow;
  color: black;
}

/* src/RegisterPage.css */

.register-container {
  max-width: 400px;
  margin: auto;
  padding: 1em;
  background-color: #f2f2f2;
  border-radius: 8px;
}

.register-title {
  text-align: center;
  color: #4A90E2;
}

.register-error {
  color: red;
}

.register-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.register-input {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.register-button {
  padding: 10px;
  background-color: #4A90E2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.register-button:hover {
  background-color: #357ABD;
}

.speed-reading-container {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  background-color: #f0f0f0;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

textarea {
  width: 80%;
  height: 100px;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  resize: none;
}

.controls {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.controls button, .controls input {
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.controls input[type="number"] {
  width: 60px;
  text-align: center;
}

.word-display {
  font-size: 3rem;
  font-weight: bold;
  margin-top: 20px;
}

.fetch-url {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;
}

.fetch-url input[type="url"] {
  width: 300px;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fetch-url button {
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.speed-reading-container {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.controls {
  margin: 20px 0;
}

.word-display h2 {
  font-size: 3rem;
  margin: 20px 0;
}

.loading-indicator {
  margin-top: 10px;
  font-weight: bold;
  color: #007bff;
}

.speed-reading-container {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.controls {
  margin: 20px 0;
}

.word-display h2 {
  font-size: 3rem;
  margin: 20px 0;
  font-weight: bold;
}

.loading-indicator {
  margin-top: 10px;
  font-weight: bold;
  color: #007bff;
}

.omdb-random-film-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.filters input {
  width: calc(100% - 20px);
  margin: 10px 0;
  padding: 10px;
}

.filters button {
  padding: 10px 20px;
  cursor: pointer;
}

.film-result {
  margin-top: 20px;
}

.film-result img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

.error {
  color: red;
  margin-top: 10px;
}