/* src/components/CookiesBanner.css */
.cookies-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f8c471;
  color: #333;
  text-align: center;
  padding: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cookies-banner p {
  margin: 0;
}

.cookies-banner button {
  background-color: #d35400;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
}

.cookies-banner button:hover {
  background-color: #e67e22;
}
