.bitrate-calculator {
  padding: 20px;
  max-width: 600px;
  margin: auto;
  font-family: Arial, sans-serif;
}

.calculator-section {
  margin-bottom: 20px;
}

.calculator-section h3 {
  margin-bottom: 10px;
}

input, select, button {
  display: block;
  margin: 5px 0;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
}

.result {
  font-weight: bold;
}
